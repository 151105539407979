//colors

$black: #000000 !default;
$gray-900: #526c79 !default;
$pink: #f94f7a !default;
$yellow: #fbf9f0 !default;
$gray-800: #555555 !default;
$brand-primary: #fe8500 !default;
$brand-secondary: #141414 !default;
$brand-tertiary: #16449a !default;
$brand-danger: #ff242b !default;
$brand-success: #10cf69 !default;
$link-danger: #e8242a !default;

$white-100: #f5f5f5;
$white-600: #f7fafc;

$gray-500: #798397;
$gray-300: #e2f9ed;
$input-border: #aeb8cb;
$input-text: #233047;
$tableHead: #f1f3f9;
$gray_02: rgba(112, 112, 112, 0.2);
$gray_025: rgba(10, 19, 36, 0.25);
$gray_05: rgba(136, 152, 170, 0.5);
$blue_03: rgba(121, 131, 151, 0.039);
$login_bg: rgba(240, 245, 250, 0.44);
// applied colors:
$link-color: $brand-primary !default;
$link-hover-color: darken($link-color, 10%) !default;
$brand-primary-hover: darken($brand-primary, 10%) !default;
$brand-secondary-hover: darken($brand-secondary, 10%) !default;
$brand-tertiary-hover: darken($brand-tertiary, 10%) !default;
$brand-danger-hover: darken($brand-danger, 10%) !default;
$brand-success-hover: darken($brand-success, 10%) !default;
