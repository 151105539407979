.non_href_a_tag { 
    cursor: pointer;
}

.text-white{
    color: white;
}

.theme_badge{
    width: 40px;
    height: 40px;
    line-height: 28px;
}

.disabled_btn {
    opacity: 0.5;
    pointer-events: none;
}

.disabled_input_field{
    background-color: #e9ecef;
}

.search_icon {
    left: auto !important;
    right: 30px;
    cursor: pointer;
    width: 20px !important;
    height: 20px !important;
    margin: 15px 15px !important;
    overflow: hidden;
    display: inline-block;
    position: absolute;
}

.close_icon {
    right: 5px !important;
    width: 15px !important;
    height: 15px !important;
    margin: 18px 15px !important;
}

.search_icon img{
    width: 100%;
    display: block;
    object-fit: cover;
    min-height: 100%;
}

.search_wrapper{
    position: relative;
}

.search_btn{
    border-radius: 5px !important;
}

.form-control:focus {
    box-shadow: none;
    outline: none;
    border-color: #16449A;
}

.logo{
    cursor: pointer;
}

.search_form_wrapper{
    display: flex;
    align-items: start;
}

.search_form_wrapper .theme_btn {
    padding: 14px 16px;
}

.mobile_search_btn{
    display: none;
}

.custom_top{
    margin-top: 30px;
}

@media screen and (max-width: 575px){
    .desktop_search_btn{
        display: none;
    }
    .mobile_search_btn{
        display: inline-block;
        width: 75%;
    }
    .search_mobile_btn_wrapper{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .search_wrapper, .search_form_container{
        width: 100%;
    }
}

.Socket_disconnect_banner {
    width: 100%;
    display: block;
    background-color: #FF3E0C;
    padding: 20px 15px;
    text-align: center;
    color: #fff;
    height: auto;
    margin-top: 80px;
}
.disconnected_socket_banner_visible {
    padding: 0;
}


@media screen and (max-width: 991px){
    .search_mobile_btn_wrapper{
        margin-top: 10px;
    }
    .Socket_disconnect_banner {
        margin-top: 65px;
    }
}

.icons_wrp_abslt {
    position: absolute;
    top: 8px;
    right: 20px;
}

.icons_wrp_abslt a {
    margin-left: 5px;
    font-size: 18px;
    color: #16449A;
}

@media screen and (min-width: 992px){
    .profile_dropdown .dropdown-menu {
        top: 40px !important;
    }
}

.comments_details_wrapper{
    padding-bottom: 50px;
}

.details_title_wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 18px;
}

.product_details_container{
    background-color: #16449A;
}

.productDetailsWrapper{
    border: 5px solid white;
    padding: 20px;
}

.productDetailsWrapper .info_title{
    color: #fff;
}

.productDetailsWrapper .info_val{
    color: #fff;
    text-align: left;
    font-size: 20px;
}

.productDetailsWrapper .order_items{
    border-color: #fff;
}

.productDetailsWrapper .ims_table tbody td{
    color: #fff;
    border-color: #fff;
}

.productDetailsWrapper .ims_table tbody tr:hover td{
    color: #fff;
}

.productDetailsWrapper .ims_table tbody tr:hover{
    background-color: #16449A;
}


body > iframe {
    display: none;
  }
