.form_group_modify {
  margin-bottom: 20px;
}
.label_modify {
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
  max-width: 100%;
  color: $brand-secondary;
}
.input_modify {
  font-weight: 400;
  color: $input-text;
  width: 100%;
  height: 52px;
  background-color: $white;
  border-radius: 5px;
  padding: 10px 16px;
  font-size: 16px;
  line-height: 1.33;
  display: block;
  border: 1px solid $input-border;
  -webkit-transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:focus,
  &:active {
    border-color: $brand-tertiary;
    outline: 0;
  }
  &::-ms-input-placeholder {
    color: $gray-500;
    opacity: 1;
  }
  &::-webkit-input-placeholder {
    color: $gray-500;
    opacity: 1;
  }
  &::placeholder {
    color: $gray-500;
    opacity: 1;
  }
}
textarea {
  height: auto !important;
}
select.cstSelect {
  background-image: image_url("icn_select_drop.svg");
  background-repeat: no-repeat;
  background-position: 96.2% center;
  background-size: 12px;
  -webkit-appearance: none;
}

.wrap-login100 {
  width: 500px;
  height: 100%;
  background: $white;
  border-radius: 10px;
  overflow: hidden;
  padding: 45px;
  margin: 40px auto 25px;
  border: 1px solid $gray_02;
  -webkit-box-shadow: 0px 0px 33px #16449a0d;
  box-shadow: 0px 0px 33px #16449a0d;
}
.login100-form {
  width: 100%;
  .theme_btn {
    min-width: 300px;
    border-radius: 25px;
    text-transform: uppercase;
  }
  .login_or.text-center {
    margin: 35px 0 20px 0;
  }
  .login100-form-title {
    display: block;
    font-size: 35px;
    color: $black;
    line-height: 1.2;
    text-align: center;
    margin-bottom: 35px;
    font-weight: 600;
  }
  .form_fields {
    margin-bottom: 35px;
  }
}
select.cstSelectW160 {
  background-position: 90.2% center;
}
